import { ParsedRecipient } from '@/presentation/recipient/model/ParsedRecipient'
import { CheckingInvalidRecipient } from '@/presentation/recipient/model/CheckingInvalidRecipient'
import { IRecipientUseCase, RecipientUseCase } from '@/usecase/recipient/RecipientUseCase'
import { CountryEnum, ReceiveMethodEnum } from '@/enums'
import { CountryPhoneCodes } from '@/data/CountryPhoneCodes';
import { formattersMap } from '@/lib/Utils';
import { Utils } from '@/static/Utils';

export interface IInvalidRecipientPresentation {
  countryWithMethods: Array<string>;
  validRecipients: Dictionary<Array<CheckingInvalidRecipient>>;
  invalidRecipients: Dictionary<Array<CheckingInvalidRecipient>>;
  invalidRecipientsWithPages: Dictionary<Dictionary<Array<CheckingInvalidRecipient>>>;
  confirmedRecipients: Dictionary<Array<CheckingInvalidRecipient>>;
  totalInvalidCount: Dictionary<number>;
  page: number;
  unit: number;
  selectedCountryWithMethod: string;
  deleteRow (countryWithMethod: string, index: number): void
  toggleUpdatable (countryWithMethod: string, index: number, callback?: any): Promise<void>;
  register (): Dictionary<Array<CheckingInvalidRecipient>>;
  hasAllInvalidCounts: boolean;
  getInvalidItemsCountries: Array<string>;
  hasInvalidRecipients: boolean;
  validateRecipient (country: keyof typeof CountryEnum, method: keyof typeof ReceiveMethodEnum, checkingInvalidRecipient: CheckingInvalidRecipient): Promise<boolean>;
  setInvalidPagination (): void;
  initialize (uploadedRecipients: Dictionary<Array<ParsedRecipient>>): Promise<void>;
  getCountryMethodLabel (countryWithMethod: string): string
}

export class InvalidRecipientPresentation implements IInvalidRecipientPresentation {
  private uploadedRecipients: Dictionary<Array<ParsedRecipient>> = {}
  private checkingInvalidRecipients: Dictionary<Array<CheckingInvalidRecipient>> = {}
  private recipientUseCase: IRecipientUseCase

  public countryWithMethods: Array<string> = []
  public confirmedRecipients: Dictionary<Array<CheckingInvalidRecipient>> = {}
  public validRecipients: Dictionary<Array<CheckingInvalidRecipient>> = {}
  public invalidRecipients: Dictionary<Array<CheckingInvalidRecipient>> = {}
  public invalidRecipientsWithPages: Dictionary<Dictionary<Array<CheckingInvalidRecipient>>> = {}
  public totalInvalidCount: Dictionary<number> = {}
  public selectedCountryWithMethod: string = ''
  public page = 1
  public readonly unit: number = 20

  constructor () {
    this.recipientUseCase = new RecipientUseCase()
  }

  private initializeCheckInvalidRecipients () {
    let id = 0
    this.countryWithMethods.forEach(countryWithMethod => {
      this.checkingInvalidRecipients[countryWithMethod] = []
      this.uploadedRecipients[countryWithMethod].forEach(uploadedRecipient => {
        const parsedRecipient = uploadedRecipient as Dictionary<any>
        Object.keys(parsedRecipient).forEach(key => {
          const value = parsedRecipient[key]
          if (value && typeof value === 'number') parsedRecipient[key] = value.toString()
        })
        const checkingInvalidRecipient: CheckingInvalidRecipient = Object.assign({}, parsedRecipient) as CheckingInvalidRecipient
        checkingInvalidRecipient.id = id++
        if (!checkingInvalidRecipient.corps_id) checkingInvalidRecipient.corps_id = ''
        if (checkingInvalidRecipient.phone) checkingInvalidRecipient.phone = checkingInvalidRecipient.phone.toString().replace(/-/gi, '')
        this.recipientUseCase.setDuplicateRecipient(checkingInvalidRecipient)
        this.checkingInvalidRecipients[countryWithMethod].push(checkingInvalidRecipient)
      })
    })
  }

  private async validateRecipientList () {
    for (const countryWithMethod of this.countryWithMethods) {
      this.validRecipients[countryWithMethod] = []
      this.confirmedRecipients[countryWithMethod] = []
      this.invalidRecipients[countryWithMethod] = []
      const country = countryWithMethod.split('_')[0] as keyof typeof CountryEnum
      const method = countryWithMethod.replace(`${country}_`, '') as keyof typeof ReceiveMethodEnum
      for (const checkingInvalidRecipient of this.checkingInvalidRecipients[countryWithMethod]) {
        const isValid = await this.validateRecipient(country, method, checkingInvalidRecipient)
        if (isValid) {
          this.validRecipients[countryWithMethod].push(checkingInvalidRecipient)
        } else {
          this.invalidRecipients[countryWithMethod].push(checkingInvalidRecipient)
        }
      }
      this.totalInvalidCount[countryWithMethod] = this.invalidRecipients[countryWithMethod].length
    }
  }

  private deleteCountryIfEmpty (countryWithMethod: string) {
    const isEmpty = !this.invalidRecipients[countryWithMethod].length && !this.confirmedRecipients[countryWithMethod].length
    if (isEmpty) {
      delete this.invalidRecipientsWithPages[countryWithMethod]
      delete this.invalidRecipients[countryWithMethod]
      delete this.confirmedRecipients[countryWithMethod]
      this.countryWithMethods = this.countryWithMethods.filter(countryWithMethodElement => {
        return countryWithMethodElement !== countryWithMethod
      })
      this.countryWithMethods = Array(...this.countryWithMethods)
    }
  }

  public get hasInvalidRecipients (): boolean {
    let hasInvalid = false
    for (const countryWithMethod of this.countryWithMethods) {
      if (!this.confirmedRecipients[countryWithMethod]) {
        hasInvalid = true
        break
      }
      const isNotInvalidCountMatched = this.totalInvalidCount[countryWithMethod] !== this.confirmedRecipients[countryWithMethod].length
      hasInvalid = hasInvalid || isNotInvalidCountMatched
    }
    return hasInvalid
  }

  public get getInvalidItemsCountries (): Array<string> {
    const invalidItemsCountries: Array<string> = this.countryWithMethods.filter(countryWithMethod => {
      return Object.keys(this.invalidRecipientsWithPages[countryWithMethod]).length
    })
    if (invalidItemsCountries.length) this.selectedCountryWithMethod = invalidItemsCountries[0]
    return invalidItemsCountries
  }

  public get hasAllInvalidCounts (): boolean {
    return this.countryWithMethods.some(countryWithMethod => {
      return this.invalidRecipients[countryWithMethod].length
    }) || !this.countryWithMethods.length
  }

  public async validateRecipient (country: keyof typeof CountryEnum, method: keyof typeof ReceiveMethodEnum, checkingInvalidRecipient: CheckingInvalidRecipient): Promise<boolean> {
    const isValid = await this.recipientUseCase.validateRecipient(country, method, checkingInvalidRecipient)
    const hasDuplicate = this.recipientUseCase.hasDuplicateRecipient(checkingInvalidRecipient)
    return isValid && !hasDuplicate
  }

  public deleteRow (countryWithMethod: string, index: number): void {
    const invalidPageRecipient = this.invalidRecipientsWithPages[countryWithMethod][this.page][index]
    this.invalidRecipientsWithPages[countryWithMethod][this.page].splice(index, 1)
    const invalidRecipientIndex = this.invalidRecipients[countryWithMethod].findIndex(recipient => {
      return recipient.id === invalidPageRecipient.id
    })
    if (invalidRecipientIndex > -1) this.invalidRecipients[countryWithMethod].splice(invalidRecipientIndex, 1)
    this.deleteCountryIfEmpty(countryWithMethod)
    this.setInvalidPagination()
    this.totalInvalidCount[countryWithMethod]--
    const isExistValidated: boolean = !!this.confirmedRecipients[countryWithMethod]
    if (!isExistValidated) return
    this.confirmedRecipients[countryWithMethod] = this.confirmedRecipients[countryWithMethod].filter(recipient => {
      return recipient.id !== invalidPageRecipient.id
    })
    this.confirmedRecipients = Object.assign({}, this.confirmedRecipients)
  }

  public async toggleUpdatable (countryWithMethod: string, index: number, callback?: any): Promise<void> {
    const updateRecipientRow = this.invalidRecipientsWithPages[countryWithMethod][this.page][index]
    const isCompleteAction = updateRecipientRow.updatable === true
    if (isCompleteAction) {
      const country = countryWithMethod.split('_')[0] as keyof typeof CountryEnum
      const method = countryWithMethod.replace(`${country}_`, '') as keyof typeof ReceiveMethodEnum
      const isValid = await this.validateRecipient(country, method,  updateRecipientRow)
      if (!isValid) {
        const validationAgain = !isValid
        if (callback) callback(validationAgain)
        return
      }
      const validatedIndex = this.confirmedRecipients[countryWithMethod].findIndex(recipient => recipient.id === updateRecipientRow.id)
      updateRecipientRow.updatable = false
      if (validatedIndex < 0) {
        this.confirmedRecipients[countryWithMethod].push(updateRecipientRow)
      } else {
        this.confirmedRecipients[countryWithMethod].splice(validatedIndex, 1, updateRecipientRow)
      }
      this.confirmedRecipients = JSON.parse(JSON.stringify(this.confirmedRecipients))
    }
    updateRecipientRow.updatable = true
    if (callback) callback()
  }

  public register (): Dictionary<Array<CheckingInvalidRecipient>> {
    Object.keys(this.validRecipients).forEach(countryWithMethod => {
      const confirmedRecipients = this.confirmedRecipients[countryWithMethod]
      if (confirmedRecipients?.length) this.validRecipients[countryWithMethod].push(...confirmedRecipients)
      if (!this.validRecipients[countryWithMethod].length) delete this.validRecipients[countryWithMethod]
    })
    return this.validRecipients
  }

  public setInvalidPagination (): void {
    this.countryWithMethods.forEach((countryWithMethod) => {
      if (!this.invalidRecipientsWithPages[countryWithMethod]) this.invalidRecipientsWithPages[countryWithMethod] = {}
      const invalidRecipients: Array<CheckingInvalidRecipient> = new Array(...this.invalidRecipients[countryWithMethod])
      let page: number = 1
      while (invalidRecipients.length > 0) {
        this.invalidRecipientsWithPages[countryWithMethod][page++] = invalidRecipients.splice(0, this.unit)
      }
    })
  }

  getCountryMethodLabel(countryWithMethod: string): string {
    const translated = formattersMap.countryWithMethod(countryWithMethod, true)
    const isTranslated = !translated.includes('.')
    if (isTranslated) return translated
    
    const translatedMethod = translated.split(' ')[1]
    const country = countryWithMethod.split('_')[0]
    const otherCountry = CountryPhoneCodes.find(countryPhone => countryPhone.iso === country.toUpperCase())
    const otherCountryName = Utils.isKoreanLocale ? otherCountry?.name_korean : otherCountry?.name
    return `${otherCountryName || country.toUpperCase()} ${translatedMethod}`
  }

  public async initialize (uploadedRecipients: Dictionary<Array<ParsedRecipient>>): Promise<void> {
    this.uploadedRecipients = uploadedRecipients
    this.countryWithMethods = Object.keys(uploadedRecipients)
    this.selectedCountryWithMethod = this.countryWithMethods[0]
    this.initializeCheckInvalidRecipients()
    await this.validateRecipientList()
    this.setInvalidPagination()
  }
}
